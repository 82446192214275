<template>
  <div>
    <div class="campaign-leaderboard">
      <div class="search">
        <el-input
          v-model="meta.search"
          v-loading="sortIsLoading"
          placeholder="Search"
          element-loading-spinner="el-icon-loading"
        >
          <i
            v-if="meta.search !== ''"
            slot="suffix"
            class="el-input__icon el-icon-circle-close el-input__clear"
            @click="meta.search = ''"
          >
          </i>
        </el-input>
      </div>
      <div class="filter-and-sort d-flex">
        <div class="tag-filter">
          <span class="txt">Show</span>
          <div class="filter">
            <ul class="hidden-sm-and-down">
              <li
                :class="{ active: meta.activeTagFilter == 'All'}"
                @click="meta.activeTagFilter = 'All'; fetchLeaderboard()"
              >
                ALL
              </li>
              <li
                v-for="tag in campaignTags"
                :key="tag.id"
                :class="{ active: meta.activeTagFilter == tag.id }"
                @click="meta.activeTagFilter = tag.id; fetchLeaderboard()"
              >
                #{{ tag.name }}
              </li>
            </ul>
            <div class="mobile-filter hidden-md-and-up">
              <el-select v-model="meta.activeTagFilter">
                <el-option
                  label="All"
                  value="All"
                  @change="fetchLeaderboard()"
                >
                </el-option>
                <el-option
                  v-for="tag in campaignTags"
                  :key="tag.key"
                  :label="tag.name"
                  :value="tag.id"
                  @change="fetchLeaderboard()"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="sort-by">
          <span class="txt">Sort by</span>
          <div class="drop-sel">
            <el-select
              v-model="meta.sort_by"
              v-loading="sortIsLoading"
              placeholder="Select"
              element-loading-spinner="el-icon-loading"
              @change="handleSortChange(); sortIsLoading = true"
            >
              <el-option
                v-for="(item, key) in sortOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="table-content">
        <div id="campaign-team-leaderboard-table">
          <table>
            <thead>
              <tr>
                <th>TEAM</th>
                <th>MEMBERS</th>
                <th>AVG. PTS PER MEMBER</th>
                <th>TOTAL POINTS</th>
                <th>PARTICIPATION RATE</th>
              </tr>
            </thead>
            <transition name="fade">
              <div
                v-if="sortIsLoading"
                v-loading="sortIsLoading"
                class="table-loader"
              ></div>
            </transition>
            <tbody v-if="rows">
              <tr v-for="row of rows">
                <td>{{ row.team }}</td>
                <td>{{ row.members }}</td>
                <td>{{ row.avg_points_per_member }}</td>
                <td>{{ row.total_points }}</td>
                <td>{{ row.participation_rate }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="leaderboard-table-pagination">
      <el-pagination
        v-if="pagination"
        :current-page.sync="pagination.current_page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import leaderboardApi from '@/modules/leaderboard/services/leaderboard.api'

export default {
  props: {
    campaignId: {
      type: Number,
      required: true,
    },
    campaignTags: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    rows: [],
    meta: {
      current_page: 1,
      per_page: 20,
      search: '',
      sort_by: 'participation_rate',
      sort: 'desc',
      activeTagFilter: 'All'
    },
    sortOptions: [
      { value: 'team', label: 'TEAM' },
      { value: 'members', label: 'MEMBERS' },
      { value: 'avg_points_per_member', label: 'AVG. PTS PER MEMBER' },
      { value: 'total_points', label: 'TOTAL. PTS' },
      { value: 'participation_rate', label: 'PARTICIPATION RATE' }
    ],
    pagination: null,
    sortIsLoading: false
  }),

  watch: {
    'meta.search': _.debounce(function (newVal, oldVal) {
      this.fetchLeaderboard()
    }, 1000)
  },

  created() {
    this.fetchLeaderboard()
  },

  methods: {
    fetchLeaderboard(customParams) {
      this.sortIsLoading = true

      leaderboardApi
        .campaignTeamLeaderboard(this.campaignId,
          _.assign({
            search: this.meta.search,
            tags: this.meta.activeTagFilter !== "All" ? this.meta.activeTagFilter : null,
            page: this.meta.current_page,
            per_page: this.meta.per_page,
            sort_by: this.meta.sort_by,
            sort: this.meta.sort_by == "team" ? 'asc' : 'desc'
          }, customParams)
        )
        .then((response) => {
          this.rows = response.data.data
          this.pagination = response.data.meta.pagination
          this.sortIsLoading = false
        })
    },
    handleSortChange(event) {
      this.fetchLeaderboard({ sort_by: event })
    },
    handleSizeChange(event) {
      this.fetchLeaderboard({ page: 1, per_page: event })
    },
    handleCurrentChange(event) {
      this.fetchLeaderboard({ page: event })
    },
  },
}
</script>