<template>
  <div class="campaign-leaderboard-iterator">
    <div class="top-header campaign"></div>
    <div class="leaderboard-table">
      <div class="container">
        <div class="filter filter-inverse inline campaign">
          <ul class="hidden-sm-and-down">
            <li
              v-for="tab in tabs"
              :key="tab.key"
              :class="{ active : activeTab == tab.key }"
              @click="activeTab = tab.key"
            >
              {{ tab.name }}
            </li>
          </ul>
          <div class="mobile-filter hidden-md-and-up">
            <el-select v-model="activeTab">
              <el-option
                v-for="tab in tabs"
                :key="tab.key"
                :label="tab.name"
                :value="tab.value"
                @click="activeTab = tab.key"
              ></el-option>
            </el-select>
          </div>
        </div>
        <keep-alive>
          <team-leaderboard
            v-if="activeTab == 'team-leaderboard'"
            :campaign-id="campaign.id"
            :campaign-tags="campaign.tags.data"
          ></team-leaderboard>
          <individual-leaderboard
            v-if="activeTab == 'individual-leaderboard'"
            :campaign-id="campaign.id"
            :campaign-tags="campaign.tags.data"
          ></individual-leaderboard>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import TeamLeaderboard from '@/modules/leaderboard/components/campaign-team-leaderboard'
import IndividualLeaderboard from '@/modules/leaderboard/components/campaign-individual-leaderboard'

export default {
  components: {
    TeamLeaderboard,
    IndividualLeaderboard
  },
  props: ['campaign'],
  data() {
    return {
      tabs: [
        { key: 'team-leaderboard', name: 'TEAM LEADERBOARD', value: 'team' },
        { key: 'individual-leaderboard', name: 'INDIVIDUAL LEADERBOARD', value: 'individual' },
      ],
      activeTab: 'team-leaderboard'
    }
  },
}
</script>